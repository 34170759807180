import $ from "jquery";
import {
  fromEvent,
  merge,
  of,
  throttleTime,
  debounceTime,
} from "rxjs";

$(function () {
  const scroll$ = fromEvent(window, "scroll");
  const resize$ = fromEvent(window, "resize");
  const scroll_resize$ = merge(scroll$, resize$, of(null));

  const $header = $(".header");
  const $naviItems = $(".navimenu .menu-item").filter(
    (i, e) => !!$(e).data("target")
  );

  //ヘッダーメニュー下線
  merge(
    scroll_resize$.pipe(throttleTime(50)),
    scroll_resize$.pipe(debounceTime(50))
  )
    .pipe(throttleTime(0, undefined, { leading: true, trailing: false }))
    .subscribe((x) => {
      var scrollTop = $(window).scrollTop();
      const $scrollPositions = $(".scroll-position");

      const $targetPosition = $scrollPositions
        .filter(
          (i, e) =>
            $(e).position().top <= scrollTop + $header.outerHeight(true) * 2
        )
        .last();

      if ($targetPosition.length) {
        const id = $targetPosition.attr("id");
        if (!$targetPosition.hasClass("current")) {
          $naviItems.removeClass("current");
          $scrollPositions.removeClass("current");
          $targetPosition.addClass("current");
          $naviItems
            .filter((i, e) => $(e).data("target") === id)
            .addClass("current");
        }
      }
    });

  scroll_resize$.subscribe((x) => {
    var scrollTop = $(window).scrollTop();
    //ヘッダー背景色
    const $topContent = $(".bg-transition-target");
    if ($topContent.length) {
      var topBottom =
        $topContent.position().top + $topContent.outerHeight(true);
      var isOverTopContentPosition =
        scrollTop > topBottom - $header.outerHeight(true);
      var hasGreenTransitionClass = $header.hasClass("bg-transition-to-pink");
      var isTransitionToGreen =
        isOverTopContentPosition && !hasGreenTransitionClass;
      var isTransitionToTransparent =
        !isOverTopContentPosition && hasGreenTransitionClass;

      if (isTransitionToGreen || isTransitionToTransparent) {
        $header.removeClass("bg-transition-to-pink");
        $header.removeClass("bg-transition-to-transparent");
      }

      if (isTransitionToGreen) {
        $header.addClass("bg-transition-to-pink");
      } else if (isTransitionToTransparent) {
        $header.addClass("bg-transition-to-transparent");
      }
    }
  });
});

import "ress";
import "../css/index.scss";
import $ from "jquery";
import "./_slick";
import "./_scrolltransition";

$(function () {
  $(".menu-trigger").on("click", function () {
    $("html").toggleClass("menu-active");
    return false;
  });
});

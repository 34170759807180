import $ from "jquery";
import "slick-carousel";
import { fromEvent, debounce, interval } from "rxjs";
import arrowLeft from "../assets/arrow-left.svg";
import arrowRight from "../assets/arrow-right.svg";
import "../assets/arrow-right.svg";

const resize$ = fromEvent(window, "resize");

$(function () {
  const $slider = $("#floor-slider");

  const options = {
    slidesToShow: 1,
    variableWidth: false,
    infinite: true,
    prevArrow: `<img class='a-left control-c prev slick-prev' src=${arrowLeft}>`,
    nextArrow: `<img class='a-right control-c next slick-next' src=${arrowRight}>`,
    responsive: [
      {
        breakpoint: 99999,
        settings: "unslick",
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const ss = $slider.slick(options);

  resize$.pipe(debounce((i) => interval(250))).subscribe((x) => {
    if ($(window).width() < 1200 && !ss.hasClass("slick-initialized")) {
      $slider.slick(options);
    }
  });
});

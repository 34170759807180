import $ from "jquery";

$(function () {
  $(".scroll-button").on("click", function () {
    const targetId = $(this).data("target");
    const $target = $("#" + targetId);

    $("html,body").animate({ scrollTop: $target.offset().top }, 500);
  });
});
